@import '../utils/color';

.page__basesLayout {
  display: flex;
  width: 100%;
  height: 100%;

  &--sidebar-navigation {
    // width: 80px;
    height: calc(100vh - calc(var(--default-layout-header-height) + var(--default-layout-footer-height)));
    // position: fixed;
    left: 0;
    top: var(--default-layout-header-height);

    .main-sidebar {
      position: fixed;
      left: 0;
      height: calc(100vh - calc(var(--default-layout-header-height) + var(--default-layout-footer-height)));
      background-color: var(--default-layout-background-aside);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .menu-list {
      padding: 5px 8px;
      color: #fff;
      position: relative;
      display: flex;
      flex-direction: column;

      .item,
      .last-item {
        padding: 8px 0px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;

        span {
          text-align: start;
          font-weight: bold;
        }

        .icon {
          font-size: 24px;
          display: flex;
          align-items: center;

          img {
            width: 36px;
          }
        }

        &:hover {
          background: $menu-hover;
        }
      }

      // .info-item,
      // .last-item {
      //   position: absolute;
      // }

      // .info-item {
      //   bottom: 70px;
      // }

      // .last-item {
      //   bottom: 0;
      // }
    }
  }

  &--content {
    width: 100%;
    height: 100%;

    .pages__container {
      padding: 60px 60px 60px 130px;
    }
  }
}

.page__basesLayout.open-menu {
  .page__basesLayout--sidebar-navigation {
    width: 40px;
    transition: width 0.5s ease-in-out;

    .menu-list {
      width: 140px;
    }
  }

  .page__basesLayout--content {
    width: calc(100vw - 40px);

    .detail-page {
      width: calc(100vw - 40px);
    }

    .analytics__filter-bar {
      left: 140px;
    }
  }
}

.page__basesLayout.close-menu {
  .page__basesLayout--sidebar-navigation {
    width: 40px;
    transition: width 0.5s ease-in-out;

    .menu-list {
      width: 40px;
    }
  }

  .page__basesLayout--content {
    width: calc(100vw - 40px);
    transition: width 0.3s ease-in-out;
    .detail-page {
      width: calc(100vw - 40px);
    }

    .analytics__filter-bar {
      left: 40px;
    }
  }
}

// @media only screen and (max-width: 1400px) {
//   .page__basesLayout {
//     display: flex;

//     &--sidebar-navigation {
//       padding: 0 12px;
//     }

//     &--content {
//       padding: 16px 32px;
//     }
//   }
// }

@media screen and (max-width: 1024px) {
  .page__basesLayout--sidebar-navigation .menu-list .item,
  .page__basesLayout--sidebar-navigation .menu-list .last-item {
    padding: 5px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    // flex-direction: column;
    cursor: pointer;

    .icon {
      font-size: 16px;
    }

    span {
      text-align: start;
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 1228.8px) {
  .page__basesLayout--sidebar-navigation .menu-list .item,
  .page__basesLayout--sidebar-navigation .menu-list .last-item {
    padding: 5px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    // flex-direction: column;
    cursor: pointer;

    .icon {
      font-size: 16px;
    }

    span {
      text-align: start;
      font-size: 11px;
    }
  }
}

button[type='button'].ant-btn {
  border-radius: 22px;
}

button[type='submit'].ant-btn {
  border-radius: 22px;
}

button.ant-btn.btn-results {
  padding-right: 12px;
  padding-left: 12px;
}

button[type='button'].success,
button[type='button'].danger,
button[type='button'].confirm,
button[type='button'].cancel {
  border-radius: 22px;
}

.ant-modal-root {
  button.success,
  button.danger,
  button.confirm,
  button.cancel {
    border-radius: 22px;
  }
}

.pages__container--div-btn-export {
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 22px;
}

.ant-form-item.analytics__filter-bar-item {
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      margin-bottom: 6px;
    }
  }
}
.ant-form-item-control-input .ant-form-item-control-input-content .ant-input-affix-wrapper.ant-input-textarea-affix-wrapper textarea {
  padding-left: 3px;
}
