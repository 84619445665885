.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.container {
  width: var(--default-layout-width);
  min-height: calc(100vh - var(--default-layout-header-height));
  max-width: 100%;
  padding: 0;
  display: flex;
}

.content {
  flex: 1;
}
