.container-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
  height: var(--default-layout-header-height);
  box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
  background-color: var(--white);

  &-avatar {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--default-layout-background-gray);

    span {
      color: var(--default-layout-background-white);
    }
  }
}

.inner {
  height: 100%;
  width: 100%;
  padding: 0 var(--default-layout-horizontal-spacer);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-link {
  display: flex;
  cursor: pointer;
  .logo {
    height: 50px;
  }

  .logo-brand {
    margin-top: 17px;
    margin-bottom: 17px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #414141;
    border-left: 1px solid #414141;
    padding-left: 15px;
  }
}

// Actions
.actions {
  display: flex;
  align-items: center;
}

.more-btn {
  font-size: 2rem;
  margin-left: 28px;
  padding: 4px 8px;
  background-color: transparent;
  cursor: pointer;
}

.action-btn {
  position: relative;
  display: flex;
  font-size: 2.2rem;
  color: #161823;
  background-color: transparent;
  padding: 4px 10px;
  cursor: pointer;
}

.badge {
  position: absolute;
  top: -3px;
  right: 0px;
  padding: 0px 6px;
  height: 2rem;
  line-height: 2rem;
  border-radius: 10px;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--white);
  font-family: var(--font-family);
  background-color: var(--primary);
}

.user-avatar {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 14px;
  cursor: pointer;
}

.container-header-opacity {
  position: relative;
  z-index: 1;
  width: 100%;
  height: var(--default-layout-header-height);
  background-color: var(--white);
}

.show {
  &__action {
    z-index: 99;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: var(--default-layout-header-height);
    right: var(--default-layout-horizontal-spacer);
    &-icon {
      padding: 4px;
      &:hover {
        background-color: rgba(23, 117, 224, 0.1);
      }
    }
    .navbar,
    .navbar-brand {
      display: flex;
      align-items: center;
    }

    .navbar-brand {
      .logo {
        height: 50px;
      }

      .logo-brand {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #414141;
        border-left: 1px solid #414141;
        padding-left: 15px;
      }
    }
    .blockDrop {
      display: contents;
    }
    &-drop {
      width: 215px;
      background: #fff;
      height: 110px;
      padding: 12px;
      box-sizing: border-box;
      border-left: 1px solid #ccced0;
      border-right: 1px solid #ccced0;
      border-bottom: 1px solid #ccced0;
      border-radius: 0px 0px 6px 6px;
    }

    &-btnLogout {
      align-items: center;
      width: 100%;
      display: flex;
      cursor: pointer !important;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin: 0;
      }

      // &:hover {
      //   background-color: var(--default-layout-background-supporting-gray1);
      // }
    }

    &-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #414141;
      margin: 0;
      &-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin: 4px 0 16px 0;
        text-transform: capitalize;
        white-space: normal;
        text-overflow: ellipsis;
        width: 97%;
      }
    }
  }
}
